import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { ACCOMMODATION_TYPE, I18N_DESCRIPTION, I18N_DESCRIPTION2, I18N_NAME, I18N_SHORTDESCRIPTION, RESORT, UNIT, setOpacityOnHide } from "../../../components/utils";
import { AccommodationType, Resort, Unit } from "../../mxts";
import { ApiCallOptions, MxtsApi, PagedResult, Resource } from "@maxxton/cms-mxts-api";
import { Content, getContent } from "../../../utils/content.util";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { getI18nLocaleStringFromParams, wrapProps } from "../../../i18n";

import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { getEnv } from "../../../utils/env.util";
import { uniqueId } from "lodash";

interface SpecialWidgetProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
    unit?: Unit;
    resort?: Resort;
    dynamicContainerOptions?: DynamicContainerOptions;
    className?: string;
}

interface SpecialWidgetStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
}

type SpecialWidgetBaseProps = SpecialWidgetProps & SpecialWidgetStoreProps;

type AccommodationTypeOrUnit = AccommodationType | Unit;

type SpecialContent = Content & { specialName: string; specialDescription: string; specialDescription2: string; specialShortDescription: string };

// eslint-disable-next-line max-lines-per-function
const SpecialWidgetBase = (props: SpecialWidgetBaseProps) => {
    const { className, options } = props;
    const [modal, setModal] = React.useState<boolean>(false);
    const [content, setContent] = React.useState<SpecialContent>();
    const [tooltipUniqueId, setTooltipUniqueId] = React.useState<string>(uniqueId("Tooltip_"));
    const specialId = getSpecialId(props, content as AccommodationType | Unit);
    const loadSpecial: boolean | undefined = content && !(content as AccommodationTypeOrUnit).specialId && !!specialId;
    const hideWidget = setOpacityOnHide(options);

    function toggle() {
        setModal(!modal);
    }

    const loadContent = React.useCallback(async () => {
        const [loadedContent, special] = await doLoadContent(props, specialId);
        if (special) {
            setContent({
                ...loadedContent,
                specialName: special?.name || "",
                specialDescription: special?.description || "",
                specialDescription2: special?.description2 || "",
                specialShortDescription: special?.shortDescription || "",
            });
        } else {
            setContent(loadedContent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSpecial]);

    React.useEffect(() => {
        loadContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSpecial]);

    const showSpecialLabel = (tooltipId?: string): JSX.Element => {
        const styles = `button-styled btn ${options.buttonColor?.includes("theme") ? `background-color-${options.buttonColor}` : !options.buttonColor?.includes("rgba") ? "button--primary" : ""}`;

        if (options.isEnableIconToShowSpecial && options.iconForSpecial) {
            return <FontAwesome id={tooltipId} onClick={toggle} name={options.iconForSpecial} className={`fontawesome-${options.iconForSpecial} icon`} />;
        } else if (options.specialTextSelector?.length) {
            return (
                <button
                    id={tooltipId}
                    className={`btn-widget special-btn ${styles}`}
                    style={{ background: (options.buttonColor?.includes("rgba") && options.buttonColor) || undefined }}
                    onClick={toggle}
                >
                    {options.specialTextSelector.map(
                        (specialText, index) =>
                            ((specialText.label === getI18nLocaleStringFromParams(I18N_NAME) || specialText.label === "Name") && (
                                <div key={`special-name-${index}`} className="special-name">
                                    {content?.specialName}
                                </div>
                            )) ||
                            ((specialText.label === getI18nLocaleStringFromParams(I18N_DESCRIPTION) || specialText.label === "Description") && (
                                <div key={`special-description-${index}`} className="special-description">
                                    {content?.specialDescription}
                                </div>
                            )) ||
                            ((specialText.label === getI18nLocaleStringFromParams(I18N_DESCRIPTION2) || specialText.label === "Description2") && (
                                <div key={`special-description2-${index}`} className="special-description2">
                                    {content?.specialDescription2}
                                </div>
                            )) ||
                            ((specialText.label === getI18nLocaleStringFromParams(I18N_SHORTDESCRIPTION) || specialText.label === "Short Description") && (
                                <div key={`special-short-description-${index}`} className="special-short-description">
                                    {content?.specialShortDescription}
                                </div>
                            ))
                    )}
                    {options.showInfoIcon && <div id="info-circle"></div>}
                </button>
            );
        }
        return (
            <button id={tooltipId} onClick={toggle} className={`btn-widget ${styles}`} style={{ background: (options.buttonColor?.includes("rgba") && options.buttonColor) || undefined }}>
                <div className="special-name">{content?.specialName}</div>
                {options.showInfoIcon && <div id="info-circle"></div>}
            </button>
        );
    };

    const renderModal = (): JSX.Element => (
        <React.Fragment>
            {content?.specialName && showSpecialLabel()}
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} className="special-title">
                    {content?.specialName}
                </ModalHeader>
                <ModalBody className="special-description">{content?.specialDescription}</ModalBody>
            </Modal>
        </React.Fragment>
    );

    const renderTooltip = (): JSX.Element | undefined => {
        if (content?.specialName && tooltipUniqueId) {
            return (
                <React.Fragment>
                    {showSpecialLabel(tooltipUniqueId)}
                    <ErrorBoundary>
                        <UncontrolledTooltip autohide={false} placement="bottom" target={tooltipUniqueId} className="notification--tooltip">
                            <span className="special-title">{content?.specialName}</span>
                            <p className="special-description">{content?.specialDescription}</p>
                        </UncontrolledTooltip>
                    </ErrorBoundary>
                </React.Fragment>
            );
        }
    };

    return content ? <div className={classNames("special-widget", `${hideWidget}`, className)}>{options.showModal ? renderModal() : renderTooltip()}</div> : null;
};

function mapStateToProps(state: State): SpecialWidgetStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

async function doLoadContent(props: SpecialWidgetBaseProps, specialId: number): Promise<[SpecialContent, Resource | null]> {
    const { availabilityState, context } = props;
    const content: SpecialContent = (await getContent(props)) as SpecialContent;
    if (content?.specialName === undefined) {
        const env: ApiCallOptions = await getEnv({ availabilityState, context });
        const special: Resource | null = specialId
            ? await (props.context.mxtsApi || MxtsApi).resources(env, { size: 1, resourceIds: [specialId as number] }).then((res: PagedResult<Resource>) => res.content[0])
            : null;
        return [content, special];
    }
    return [content, null];
}

function getSpecialId(props: SpecialWidgetBaseProps, content?: AccommodationType | Unit): number {
    const contentType: string | undefined = props.options.contentType || props.dynamicContainerOptions?.contentType;
    let specialId = 0;
    if (contentType) {
        if (contentType === RESORT) {
            specialId = props.resort?.curatedAccommodation?.specialId || 0;
        }
        if (contentType === ACCOMMODATION_TYPE && (content as AccommodationType)?.resourceId === props.availabilityState.availabilityResult?.response.resources?.[0]?.resourceId) {
            specialId = props.availabilityState.availabilityResult?.response.resources?.[0]?.specialId;
        }
        if (contentType === UNIT && props.availabilityState.availabilityResult?.response.units?.[0]?.unitId) {
            specialId = content?.specialId || props.availabilityState.availabilityResult?.response.units?.[0]?.specialId;
        }
    }
    return specialId;
}

export const SpecialWidget = wrapProps<SpecialWidgetProps>(connect<SpecialWidgetStoreProps>(mapStateToProps)(SpecialWidgetBase));

export async function warmupCache(props: SpecialWidgetBaseProps): Promise<void> {
    const specialId = getSpecialId(props);
    await doLoadContent(props, specialId);
}
