import * as Icons from "../../page/icons";
import * as React from "react";

import { DESCRIPTION, DESCRIPTION2, I18N_DESCRIPTION, I18N_DESCRIPTION2, I18N_NAME, I18N_SHORTDESCRIPTION, NAME, SHORT_DESCRIPTION, getContentTypeSelector } from "../../../components/utils";
import { DefaultIcons, IconSelect } from "../../../plugins/page/assetpublisher/index";
import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { ResultOptions, SharedWidgetParams } from "../../widget";
import { SpecialWidget, warmupCache } from "./Special";
import { TypesearchContainerWidgetSpec, Widget } from "../../";

import { ButtonColor } from "../../page/button/index";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { MultiSelectOptionStringValue } from "@maxxton/cms-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds?: MultiSelectOptionStringValue[];
    contentTypeSelector?: boolean;
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    showSpecialText: boolean;
    specialTextSelector: NumberMultiSelectOption[];
    isEnableIconToShowSpecial: boolean;
    iconForSpecial: string;
    showModal: boolean;
    buttonColor: ButtonColor;
    showInfoIcon: boolean;
}

interface WidgetOptionsFormParams {
    widgetOptionsFormId: string;
}

const TARGETS = ["special"];
const iconList: IconSelect[] = Object.keys(Icons.default).map((key: string) => ({ label: (Icons.default as DefaultIcons)[key], value: key }));

const widgetOptionsForm = ({ widgetOptionsFormId }: WidgetOptionsFormParams): FormSpec<WidgetOptions> => ({
    id: widgetOptionsFormId,
    name: getI18nLocaleObject(namespaceList.dynamicSpecial, "specialWidget"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicSpecial, "specialWidgets"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [...getContentTypeSelector()],
                        [
                            {
                                variable: "showSpecialText",
                                label: getI18nLocaleObject(namespaceList.dynamicSpecial, "showSpecialText"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "specialTextSelector",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "amenityFieldMultiSelect"),
                                type: "multiselect",
                                optionList: () => [
                                    {
                                        value: NAME,
                                        text: I18N_NAME,
                                    },
                                    {
                                        value: DESCRIPTION,
                                        text: I18N_DESCRIPTION,
                                    },
                                    {
                                        value: DESCRIPTION2,
                                        text: I18N_DESCRIPTION2,
                                    },
                                    {
                                        value: SHORT_DESCRIPTION,
                                        text: I18N_SHORTDESCRIPTION,
                                    },
                                ],
                                visible: (options: WidgetOptions): boolean => options.showSpecialText,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetAgenda, "styling"),
                    properties: [
                        [multiSelectStylePicker("styleIds", TARGETS)],
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.dynamicSpecial, "specialLabelInfo"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.styleIds?.some((styleId: MultiSelectOptionStringValue) => styleId.value === "special-on-image-gallery"),
                            },
                            {
                                variable: "isEnableIconToShowSpecial",
                                label: getI18nLocaleObject(namespaceList.dynamicSpecial, "isEnableIconToShowSpecial"),
                                type: "checkbox",
                            },
                            {
                                variable: "iconForSpecial",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.isEnableIconToShowSpecial,
                                optionList: iconList,
                            },
                            {
                                variable: "showInfoIcon",
                                label: getI18nLocaleObject(namespaceList.dynamicSpecial, "showInfoIcon"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !!options.styleIds?.some((styleId: MultiSelectOptionStringValue) => styleId.value === "special-on-image-gallery"),
                            },
                            {
                                variable: "showModal",
                                label: getI18nLocaleObject(namespaceList.dynamicSpecial, "showModal"),
                                type: "checkbox",
                            },
                            {
                                variable: "buttonColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "color"),
                                type: "dual-color",
                                default: "button--primary",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
});

export const sharedSpecialWidget = ({ widgetId, widgetType, widgetOptionsFormId }: SharedWidgetParams): TypesearchContainerWidgetSpec<WidgetOptions> => ({
    id: widgetId,
    type: widgetType,
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.dynamicSpecial, "specialWidget"),
    description: getI18nLocaleObject(namespaceList.dynamicSpecial, "specialWidgetDescription"),
    optionsForm: widgetOptionsForm({ widgetOptionsFormId }),
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        showSpecialText: false,
        specialTextSelector: [],
        isEnableIconToShowSpecial: false,
        iconForSpecial: "",
        showModal: false,
        buttonColor: "button--primary",
        showInfoIcon: false,
    }),
    async render(
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions: SitemapPageLinkWidgetOptions,
        resultOptions: ResultOptions,
        dynamicContainerOptions: DynamicContainerOptions
    ) {
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds || []);
        if (isServerSide()) {
            await warmupCache({
                context,
                options: widget.options,
                availabilityState: {},
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                myEnvState: {},
                className,
                dynamicContainerOptions,
                ...(resultOptions || {}),
            });
        }
        return <SpecialWidget dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={className} />;
    },
    container: false,
});
